import React from "react";
import { useState } from "react";
import { Wrapper, VirtualMobile, ButtonWrapper, ListWrapper, Separator, FormFieldWrapper,LandingSlider,PhotosWrapper, CarrouselWrapper2, ImageWrapper, ArtistBioWrapper,ArtistImage, ArtistBio,Atelier,GifTile,CacheCache,Quote,QuoteWrapper, Slide, SlideImage,ImageArtistWrapper, ArtistBioText} from "./Reminiscence.module.css";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import addToMailchimp from 'gatsby-plugin-mailchimp'
import FELIXHANAPORTRAIT from '../../../../res/Reminiscence/felix & hannah/portrait.jpg'
import DJABRILPROTRAIT from '../../../../res/Reminiscence/djabril/portrait.jpg'
import CLEMENCEPROTRAIT from '../../../../res/Reminiscence/clemence/portrait.jpg'
import HAKIMPROTRAIT from '../../../../res/Reminiscence/hakim/portrait.jpg'
import FHOeuvre1 from '../../../../res//Reminiscence/felix & hannah/1.png'
import FHOeuvre2 from '../../../../res//Reminiscence/felix & hannah/2.png'
import FHOeuvre3 from '../../../../res//Reminiscence/felix & hannah/3.png'
import DjabrilOeuvre1 from '../../../../res//Reminiscence/djabril/1.png'
import DjabrilOeuvre2 from '../../../../res//Reminiscence/djabril/2.png'
import DjabrilOeuvre3 from '../../../../res//Reminiscence/djabril/3.png'
import ClemenceOeuvre1 from '../../../../res//Reminiscence/clemence/1.jpg'
import ClemenceOeuvre2 from '../../../../res//Reminiscence/clemence/2.jpg'
import ClemenceOeuvre3 from '../../../../res//Reminiscence/clemence/3.jpg'
import HakimOeuvre1 from '../../../../res//Reminiscence/hakim/1.png'
import HakimOeuvre2 from '../../../../res//Reminiscence/hakim/2.png'
import HakimOeuvre3 from '../../../../res//Reminiscence/hakim/3.png'
import REMINISCENCE from "../../../../res/Reminiscence/slide.png"
import REMINISCENCETILE from "../../../../res/Reminiscence/tile.png"
import Title from "../../../../components/Title";


// import FeedInstagram from "../../../../components/FeedInstagram";


const Landing = () => {


  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      slidesToSlide: 1 // optional, default to 1.
    }
  };

  const responsiveArtist = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 1,
      paritialVisibilityGutter: 0
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
      paritialVisibilityGutter: 0
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
      paritialVisibilityGutter: 0
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1,
      paritialVisibilityGutter: 0
    },
  };


  const oeuvresFH = 
    {
      photos: [
        { src: FHOeuvre1, artist: "Félix Taburet", title: "Polemos panton pater etsi, 2022", dimension: "210 x 165 cm", copyright: "© Félix Taburet et Hannah Becquante", style: "Marbre, Mortier, Pigments"},
        { src: FHOeuvre2, artist: "Félix Taburet", title: "Cris sans écho, signes de mort, temps hors mémoire, 2020", dimension: "43 x 50 x 45 cm", copyright: "© Félix Taburet et Hannah Becquante", style: "Mortier, Pigments, suie"},
        { src: FHOeuvre3, artist: "Félix Taburet et Hannah Becquante", title: "Jusqu’au feu,, 2022", dimension: "110 x 7 x 80 cm", copyright: "© Félix Taburet et Hannah Becquante", style: "Beton, marbre"},
      ],
  };

  const oeuvresDJABRIL = 
  {
    photos: [
      { src: DjabrilOeuvre1, artist: "Djabril Boukhenaïsi", title: "Portrait de Bogdan, 2020", dimension: "162 x 130 cm", copyright: "© Camélia Chaira", style: "Peinture à l’huile et pastel sur toile"},
      { src: DjabrilOeuvre2, artist: "Djabril Boukhenaïssi", title: "Etude, 2022", dimension: "40 x 50 cm", copyright: "© Camélia Chaira", style: "Peinture à l'huile et pastel sur toile"},
      { src: DjabrilOeuvre3, artist: "Djabril Boukhenaïsi", title: "Camélia dans l’herbe, 2022", dimension: "50 x 40 cm", copyright: "© Camélia Chaira", style: " Peinture à l’huile et pastel sur toile"},
    ],
  };

  const oeuvresCLEMENCE = 
  {
    photos: [
      { src: ClemenceOeuvre1, artist: "Clémence Appie Gbonon", title: "Figure, 2023", dimension: "30 x 34 cm", copyright: "© Clémence Appie Gbonon", style: "Peinture à l’huile sur toile"},
      { src: ClemenceOeuvre2, artist: "Clémence Appie Gbonon", title: "Heat Wave, 2023", dimension: "40 x 30 cm", copyright: "© Clémence Appie Gbonon", style: "Peinture à l’huile et acrylique sur toile"},
      { src: ClemenceOeuvre3, artist: "Clémence Appie Gbonon", title: "Above the Green Carpet, 2023", dimension: "40 x 30 cm", copyright: "", style: "Peinture à l’huile, acrylique et pastel sur toile"},
    ],
  };
  const oeuvresHAKIM = 
  {
    photos: [
      { src: HakimOeuvre1, artist: "Hakim Sahiri", title: "Joseph II - Gogo no Eiko, 2023", dimension: "91 x 112 cm", copyright: "© Hakim Sahiri", style: "Peinture à l’huile sur toile"},
      { src: HakimOeuvre2, artist: "Hakim Sahiri", title: "La main verte, 2023", dimension: "22 x 27 cm,", copyright: "© Hakim Sahiri", style: "Peinture à l’huile sur toile"},
      { src: HakimOeuvre3, artist: "Hakim Sahiri", title: "Joseph, Nod out, 2022", dimension: "40,5 x 33 cm", copyright: "© Hakim Sahiri", style: "Peinture à l’huile sur toile"},
    ],
  };

  // const [email, setEmail] = useState("");

  const [disp, setDisp] = useState(false);
  const [email, setEmail] = useState("");
  const [result, setResult] = useState(null);
  const [fields, setFields] = useState({
    FNAME: '',
    LNAME: ''
  })

  const swapDisp=()=> {
    setDisp((disp) => !disp);
    console.log("disp === " + disp);
  }
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email, fields);
    setResult(result);
    console.log("result=>",result.result);
    // but you can do whatever you want
  }

  const handleEmail = async (e) => {
    e.preventDefault();

    setEmail(e.target.value);
    console.log("email=>".email);

  }
  const handleName = async (e) => {
    e.preventDefault();
    setFields({
      FNAME: e.target.value
    });
    console.log("fields=>".fields);
  }
  return (
    <div >
        <div className={GifTile}>
        <img src={REMINISCENCETILE} style={{backgroundSize:"contain", width: "80vw", paddingTop: "25px"}}></img>
        </div>
        <div className={LandingSlider}>
        <Carousel 
        swipeable={false}
        draggable={false}
        slidesToSlide={1}
        responsive={responsive}
        autoPlay={false}
        autoPlaySpeed={2000}
        keyBoardControl={true}
        partialVisible={false}
        removeArrowOnDeviceType={["tablet", "mobile"]}
        dotListClass="custom-dot-list-style"
        >
      <div style={{height: "100vh",display: "flex", position: "relative",paddingTop: "35px"}}>
        <img src={REMINISCENCE} style={{backgroundSize:"contain", height: "100vh", width: "100vw"}}></img>
      </div>
    </Carousel>
        </div>
      <div className={Wrapper}>
      <br />
      <div className={CacheCache}>
        <div style={{display: "flex", width: "100%", justifyContent: "space-between"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "60px", lineHeight: "77px", fontWeight: "bold"}}>REMINISCENCE</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Clémence Appie Gbonon</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Hakim Sahiri</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Félix Taburet & Hannah Becquante</p>
            <p style={{fontSize: "34px", lineHeight: "43px", marginTop: "-4px"}}>Djabril Boukhenaïssi</p>
            <p style={{fontSize: "16px", lineHeight: "21px", margin: "9px 0 0"}}>GROUP SHOW</p>
          </div>
          <div style={{display: "flex"}}>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", marginBottom: "30px", fontWeight: "bold"}}>
            13 Octobre - 18 Novembre 2023
            </p>
          </div>
          <div style={{margin: "25px"}}>
            <p style={{fontSize: "28px", lineHeight: "36px", margin: "0 0 19px", fontWeight: "bold"}}>PARIS</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>62 RUE DU FAUBOURG SAINT-HONORÉ</p>
            <p style={{fontSize: "16px", lineHeight: "21px"}}>75008 PARIS FRANCE</p>
          </div>
          </div>
        </div>
        <div style={{display: "flex",width: "50%", paddingBottom: "40px", paddingLeft: "50px"}}>
          {/* <a href="/fr/contactez-nous">
            <p style={{textDecoration: "underline", fontWeight: "bold"}}>CATALOGUE SUR DEMANDE</p>
          </a> */}
          <a  style={{textDecoration: "none"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>PRESS</p></a>
          <a href="/en/contactez-nous" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>CONTACT</p></a>
          <a href="/en/visite-virtuelle-reminiscence" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VIRTUAL VISIT</p></a>
        </div>
      </div>
        <div className={VirtualMobile}>
          <a href="/en/visite-virtuelle-reminiscence" style={{textDecoration: "none", paddingLeft: "10px"}}><p style={{textDecoration: "underline", fontWeight: "bold"}}>VIRTUAL VISIT</p></a>
        </div>

        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}}>
        The artist's genius is to make ghosts seem real, to offer a recomposed vision, transposed by the sensitive plate of memory, and revealed by the work. Reminiscence offers a journey, an intimate wander through imagination and reality, between the darkness of night and the sensitive glow of day, blinding and suspended; between the belated awareness of dreams and their evanescence as soon as their ghosts are revealed. "The only true voyage, the only bath of youth, would not be to go towards new landscapes, but to have other eyes, to see the universe through the eyes of another, of a hundred others, to see the hundred universes that each of them sees, that each of them is." Marcel Proust, La Prisonnière
       </p>
        <br />
        </div>
            <div style={{backgroundColor: "#ededed", padding: "25px", marginTop: "50px", display: "flex", flexDirection: "column", marginBottom: "50px"}}>
              
              <p style={{alignContent: "center", alignItems:"center", alignSelf: "center"}}>WHAT DOES "REMINISCENCE" MEAN TO YOU?</p>
              <br />
              <br />
              <br />
            <p className={Quote} style={{alignSelf: "center"}}>
            From dusk to dawn, Reminiscence traces the path taken by Felix, Hannah, Hakim, Clémence and Djabril. This conjuncture invokes a return to the spirit through images, sensations and emotions, leaving an unalterable trace in reality. <strong>Augustin Rothea</strong>
            </p>
          <div className={QuoteWrapper}>
  
          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
          <p className={Quote}>
          Proust, of course, but also Bergson and Bachelard, the authors I'm most interested in at the moment. Reminiscence is linked to loss and reconstruction, because every memory is the product of an assembly and shaping of the past. It's from this word that I'm trying to work, to understand what's happening and to build a picture from what we draw from these phenomenological experiences. (...)
There are materials that can help us work on these questions, and I'm convinced that pastel is one of them. This is partly what's at stake in the paintings I'm showing at Ghost, which are formal attempts to evoke these notions.. <strong>Djabril Boukhenaïssi</strong>
            </p>
            <br />
            <br />
            <p className={Quote}>
            Trying to understand how people behave in society, through body language where, paradoxically, it gives a share of intimacy in the public space; that's what I like. <strong>Hakim Sahiri</strong>
            </p>
          </div>

          <div style={{fontSize:"18px", textAlign:"justify", margin: "0 auto", width: "80vw",padding: "25px", color: "#272727"}}>
          <p className={Quote}>
          The term Reminiscence doesn't choose between the insurmountable image of a previous existence and the memory that unknowingly takes us back to a certain point in our lives. What remains is the hesitation that, from one edge of the word to the other, keeps the expression gaping between its two shores. We don't want to bet on one or the other, believing that it's the very state of indecision that must be kept open, provided that from here memory ruins itself by its margins, erases its contours, and becomes undifferentiated by osmosis between us.<strong>Félix Taburet et Hannah Becquante</strong>
            </p>

            <br />
            <br />
            <p className={Quote}>
            To distance oneself from the subject, not to see it as a deliberate portrait, but as a symbolic figure that has no other meaning than to be painting. <strong>Clémence Gbonon</strong>
            </p>
          </div>
        </div>
        </div>

        
        <div className={Wrapper}>
        <p style={{fontSize:"18px", textAlign:"justify", lineHeight: "24px"}} >
        As part of the Ghost project exhibition programme, which focuses on the young contemporary art scene, Ghost galerie Paris is presenting its second group show featuring French artists. Réminiscence explores the work of five artists from distinct and iridescent worlds. Perpetually in search of new mediums, they blend the enigmatic with the spiritual; and, turning the breath of sleep into the breath of life, they make their art the shimmering echo of their cry.
        <br />
        So we have to ask ourselves: Should we always bring back ? Because as we complete this nocturnal journey, we can't help but wonder about the phantasmagoria that haunts our train of thought. What are these barely recognisable figures? Memories, fantasies, photographic images? Certainly all at once, but surely art, a dreamlike projection transcended by paper, canvas or stone. And in constant opposition to reality, they are the absurd evidence of the world; dream and life, and life put into dreams; eternal echoes, movements of all things. And night is only the path, or the road, or the ford. Léon Vuillecard <br />
          </p>
        <br />
        <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              // style={{height: "450px"}}
              src={FELIXHANAPORTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Félix Taburet & Hannah Becquante</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1998 à Barcelone, Espagne</p> */}
            <p className={ArtistBioText}>
            Night is a journey that Hannah and Félix take us on. When they hear its name, they both marvel: it's the murmur of day that fades, delivering with it its absence. In this poetics of emptiness - a crumbling mosaic, amputated hands, a head without a body - emptiness reigns everywhere. He leaves the viewer with landscapes of nocturnal spaces, frozen, motionless and silent, or not so; for Félix and Hannah warn: don't freeze a nature, don't seal an image, but let yourself be fooled, for they are only ghosts.
              </p>
              <br />
            {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueAna}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresFH.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>
                <img
                  className={ImageWrapper}
                  style={{objectFit: "contain"}}
                  // key={image.name}
                  // alt={image.name}
                  src={image.src}
                />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
        </div>
      </div>
      </div>
      <div className={Wrapper}>
      <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={CLEMENCEPROTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Clémence Appie Gbonon</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p> */}
            <p className={ArtistBioText}>
            <p className={ArtistBioText}>
            Dreams are an integral part of her work. Clémence Appie Gbonon tries to forget the conscious mind to allow the images that flow to take shape. The result is the male torso, a symbol of sensual, vulnerable masculinity.
            This form is the receptacle of a multitude of projections, but it is above all a figure of painting, powerful, tactile and sensual. A dreamlike quality that condenses in the canvas's ultimate memory; a mystery that materializes in enigmatic scenes, imposing on the eye a beyond to the work: the emerging figure of the incompleteness of being.
            </p>
            </p>
            <br />
          {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresCLEMENCE.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
    </div>
    </div>
    <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={DJABRILPROTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Djabril Boukhenaïssi</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p> */}
            <p className={ArtistBioText}>
            Djabril Boukhenaïssi offers the temptation of memory, its heuristics, taking with it the backwash of images that accompany it. There, in front of his erased canvases, the memorial unveils itself; incessant comings and goings of half-painted ghosts. He modulates on the reminiscence of memories and the fragile essence of memory. But these are just memories. But are they really? Or is it just the perpetual mythology of the being that exists in everyone? Created, distorted and erased, only to begin again. These paintings are like reconstructions that awaken and die at every moment. But Djabril always asks: What remains of our memories?
            </p>
            <br />
          {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresDJABRIL.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
    </div>
    </div>
    <div className={ArtistBioWrapper}>
          <div className={ArtistImage}>
            <div style={{width: "300px", height: "300px", display: "flex", justifyContent: "center", alignItems: "center"}}>
            <img
              className={ImageArtistWrapper}
              style={{objectPosition: "0 0"}}
              src={HAKIMPROTRAIT}
            />
            </div>
          </div>
          <div className={ArtistBio} style={{paddingTop: "100px"}}>
            <p style={{fontSize: "30px", lineHeight:"38px", margin: "0 0 26px"}}>Hakim Sahiri</p>
            {/* <p style={{fontSize: "19px", lineHeight:"32px", fontWeight: "bold"}}>Né en 1996 à Paris, France</p> */}
            <p className={ArtistBioText}>
            Hakim Sahiri's eye captures subjects in a trance. It is the inemuri that accompanies them. Inemuri is a state of dormancy that exists only because one surrenders and the other one wonders. There is a dialogue between the space of the public gaze and the sleep of the abandoned, the quintessence of intimacy. The artist offers us a poetic contemplation of sleeping bodies, or rather, the vulnerability of the being that offers itself entirely, to the inexorable indifference of the world - what do they dream of?
            </p>
            <br />
          {/* <a style={{color: "#272727", fontSize: "16px", fontWeight: "bold",}} href={CatalogueMathias}>Télécharger le catalogue des oeuvres</a> */}
          </div>
        </div>
        <div className={PhotosWrapper}>
        <div className={CarrouselWrapper2}>
        <Title title={"Sélection"} />
        <Carousel
            slidesToSlide={1}
            arrows={true}
            swipeable={true}
            draggable={true}
            responsive={responsiveArtist}
            infinite={false}
            customTransition="transform 500ms ease-in-out"
            transitionDuration={500}
            autoPlay={false}
            autoPlaySpeed={3000}
            showDots={false}
            partialVisbile={true}
            >
            {/* TODO: Faire les cartel ici */}
            {oeuvresHAKIM.photos.map((image) => (
              <div className={Slide}>
                <div className={SlideImage}>

                  <img
                    className={ImageWrapper}
                    style={{objectFit: "contain"}}
                    // key={image.name}
                    // alt={image.name}
                    src={image.src}
                  />
                </div>
                <div  style={{display: "flex",  alignItems: "start",flexDirection: "column", padding:"10px", width: "240px"}}>
                <strong>{image.artist}</strong>
                <i>{image.title}</i>
                <p>{image.style}</p>
                <p>{image.dimension}</p>
                <p>{image.copyright}</p>
                </div> 
                </div>
            ))}
          </Carousel>
    </div>
    </div>
    </div>
      <br />
          <br />
          <br />
      </div> 
  );
};

export default Landing;