// extracted by mini-css-extract-plugin
export var Addresses = "Footer-module--Addresses--B+zw7";
export var AideWrapper = "Footer-module--AideWrapper--uMpzo";
export var CopyrightWrapper = "Footer-module--CopyrightWrapper--NO62L";
export var FollowWrap = "Footer-module--FollowWrap--bLgAg";
export var FooterResp = "Footer-module--FooterResp--g-GnP";
export var FooterWrapper = "Footer-module--FooterWrapper--nwcZu";
export var FourthResp = "Footer-module--FourthResp---bX2g";
export var HorairesTextWrapper = "Footer-module--HorairesTextWrapper--ZE5Uv";
export var HorairesWrapper = "Footer-module--HorairesWrapper--OeT5h";
export var Icon = "Footer-module--Icon--VNFet";
export var LineWrapper = "Footer-module--LineWrapper--dlL9X";
export var LinkWrapper = "Footer-module--LinkWrapper--obai1";
export var LogoLinkWrapper = "Footer-module--LogoLinkWrapper--VOylc";
export var LogoWrapper = "Footer-module--LogoWrapper--2idqm";
export var MuseumInfosWrapper = "Footer-module--MuseumInfosWrapper--RMqvv";
export var NetworksLinkWrapper = "Footer-module--NetworksLinkWrapper--9xg0u";
export var NetworksWrapper = "Footer-module--NetworksWrapper--6cdIJ";
export var PaddingMobile = "Footer-module--PaddingMobile--Mx+0a";
export var Spacer = "Footer-module--Spacer--QZ94+";
export var ThirdResp = "Footer-module--ThirdResp--p9hDz";
export var Wrapper = "Footer-module--Wrapper--3OotT";