// extracted by mini-css-extract-plugin
export var Container = "Navbar-module--Container--khfpv";
export var DropdownImageWrapper = "Navbar-module--DropdownImageWrapper--UQ-jA";
export var DropdownMenu = "Navbar-module--DropdownMenu--sVUvA";
export var FlagImageWrapper = "Navbar-module--FlagImageWrapper--hnNkS";
export var HistoryButton = "Navbar-module--HistoryButton--nJI6T";
export var HistoryContent = "Navbar-module--HistoryContent--P0C-d";
export var HistoryDropdown = "Navbar-module--HistoryDropdown--oQrw5";
export var NavbarProjectsWrapper = "Navbar-module--NavbarProjectsWrapper--Ez3Z9";
export var SecondBar = "Navbar-module--SecondBar--rJpBw";
export var TitleWrapper = "Navbar-module--TitleWrapper--8OHzT";
export var WhiteTextLink = "Navbar-module--WhiteTextLink--IUbeo";
export var dropbtn = "Navbar-module--dropbtn--Hb0q7";
export var dropdown = "Navbar-module--dropdown--ABSKg";
export var dropdownContent = "Navbar-module--dropdownContent--H6miD";